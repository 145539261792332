import {
    DashboardDataContainerBase
} from "@/store/models/shared/DashboardDataContainerBase";

export class BusinessDashboardProductAreasContainer extends DashboardDataContainerBase{
    constructor(obj: any) {
        super(obj);
    }
}

