<template>
    <div class="platform-dashboard-pages" v-if="!loading">
        <div class="platform-dashboard-pages__filter" :class="{'disabled-filter': !businessDashboardProductAreaContainer}">
            <BusinessDashboardProductAreasFilters
                :dashboardProductAreasFiltersState="dashboardProductAreasFiltersState"
                @onApplyFilters="fetchProductAreasData"
            />
        </div>

        <div v-if="businessDashboardProductAreaContainer" class="platform-dashboard-pages__content">
            <DashboardCommonHeader
                class="mb-5"
                :title="$t('Product Areas')"
                :description="$t('Click on any of the product area from the list for more details on it')"
                :count="businessDashboardProductAreaContainer.totalScore.count"
                :add-button-text="$t('Manage Product Areas')"
                title-icon="/media/buying/icons/product-areas-icon.svg"
                @onSortChange="handleSortChange"
                @onClickAdd="handleClickAdd"
            />

            <DashboardStatsTable
                :score-title="$t('Company Score - All Product Area(s)')"
                :score-text="$t('Based on all your Product Areas/filter applied')"
                :total-score="businessDashboardProductAreaContainer.totalScore"
            >
                <template #content>
                    <div class="stats-collapse">
                        <template v-if="businessDashboardProductAreaContainer.containerData.length">
                            <transition-group name="group-transition">
                                <div :key="item.item.name" v-for="item of businessDashboardProductAreaContainer.containerData">
                                    <DashboardStatsCollapseItem
                                        :containerItem="item"
                                        :filterData="dashboardProductAreasFiltersState"
                                        :pageType="BusinessPageTypes.PRODUCT_AREA"
                                    />
                                </div>
                            </transition-group>
                        </template>
                        <div class="section-card section-card__border" v-else>
                            <DashboardEmptyState
                                icon="/media/buying/icons/speedometer-icon.svg"
                                :title="$t('No Product Area(s) Found')"
                                :text="$t('Your company don\'t have any data for the product area(s) selected. Create a feedback session to start reviewing and get insights into your banks performance.')"
                                :button-text="$t('Add Product Area')"
                                button-link="/business/settings/user-settings/overview"
                                button-icon="/media/buying/icons/plus.svg"
                                class-name="mb-10"
                            />
                        </div>
                    </div>
                </template>
            </DashboardStatsTable>
        </div>
        <CustomLoader v-else />
    </div>
</template>

<script>
import DashboardCommonHeader from "@/buying-teams/shared-components/dashboard/DashboardCommonHeader";
import DashboardStatsTable from "@/buying-teams/shared-components/dashboard/DashboardStatsTable";
import DashboardStatsCollapseItem from "@/buying-teams/shared-components/dashboard/DashboardStatsCollapseItem";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import store from "@/store";
import { diagLog } from "@/core/helpers/GlobalHelper";
import BusinessDashboardProductAreasFilters from "@/buying-teams/pages/business/dashboard/components/BusinessDashboardProductAreasFilters";
import DashboardEmptyState from "@/buying-teams/shared-components/dashboard/DashboardEmptyState";
import {
    BusinessDashboardProductAreasContainer
} from "@/store/models/business/dashboard/level2/BusinessDashboardProductAreasContainer";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";
import { BusinessPageTypes } from "@/store/enums/business/BusinessPageTypes";
import BusinessFiltersTypeEnum from "@/store/enums/business/BusinessFiltersTypeEnum";

export default {
    name: "ProductAreas",
    components: {
        CustomLoader,
        DashboardEmptyState,
        BusinessDashboardProductAreasFilters,
        DashboardStatsCollapseItem,
        DashboardStatsTable,
        DashboardCommonHeader
    },
    data() {
        return {
            loading: true,
            businessDashboardProductAreaContainer: null,
            BusinessPageTypes
        };
    },
    computed: {
        businessData() {
            return store.getters.business;
        },
        dashboardProductAreasFiltersState() {
            return store.getters.getBusinessFiltersState.dashboardProductAreas
        },
    },
    created() {
        setCurrentPageBreadcrumbs({
            title: this.businessData.name,
            logo: this.businessData.icon_path
        });
    },
    async mounted() {
        store.dispatch('initBusinessFiltersData', BusinessFiltersTypeEnum.DASHBOARD_PRODUCT_AREAS);
        this.loading = false;
        await this.fetchProductAreasData(this.dashboardProductAreasFiltersState);
    },
    methods: {
        handleSortChange(sorting) {
            this.businessDashboardProductAreaContainer.sort(sorting);
        },
        handleClickAdd() {
            this.$router.push("/business/settings/user-settings/settings");
        },
        async fetchProductAreasData(filter = null) {
            try {
                diagLog("filter ", filter);
                this.businessDashboardProductAreaContainer = null;
                let res = await store.dispatch("fetchBusinessDashboardDataLevelTwo", {filter, type: BusinessPageTypes.PRODUCT_AREA});
                this.businessDashboardProductAreaContainer = new BusinessDashboardProductAreasContainer(res);
                diagLog("fetchProductAreasData DATA", res);
                diagLog("fetchProductAreasData MODEL", this.businessDashboardProductAreaContainer);
            } catch (e) {
                console.error(e);
            }
        }
    }
};
</script>
