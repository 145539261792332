<template>
    <div class="stats-collapse__item" :class="{'is-collapsed': isCollapsed}">
        <div class="stats-collapse__item--header">
            <div class="stats-header-left">
                <div class="stats-header-left__button" @click="isCollapsed = !isCollapsed">
                    <inline-svg src="/media/buying/icons/arrow-black.svg" width="12px" height="12px" />
                </div>
                <div class="stats-header-left__name">
                    <img
                        v-if="containerItem.item.iconPath"
                        :src="containerItem.item.iconPath" alt=""
                    >
                    <a href="" @click.prevent="handleMainItemClick">
                        <span>{{ containerItem.item.name }}</span>
                    </a>
                </div>
            </div>
            <div class="stats-header-right">
                <DashboardStatsValues
                    :score="containerItem.item.stats.score"
                    :importance="containerItem.item.stats.importance"
                    :gap="containerItem.item.stats.gap"
                    :respondents="containerItem.item.stats.respondents"
                    :scores="containerItem.item.stats.scores"
                />
            </div>
        </div>

        <Transition name="fade">
            <div class="stats-collapse__item--content" v-if="isCollapsed">
                <div class="content-item"
                     v-for="item in containerItem.groups"
                     :key="item"
                >
                    <el-divider content-position="left">{{ item.title }}</el-divider>

                    <div class="content-item__child">
                        <div class="content-item__child--item"
                             v-for="(child, index) in item.itemData"
                             :key="index"
                        >
                            <a :href="child.redirectUrl" @click.prevent="handleChildItemClick(item.type, child.redirectUrl, containerItem.item.name)">
                                <div v-if="child.iconPath" class="child-item-name-image">
                                    <img :src="child.iconPath" alt="">
                                    <span>{{ child.name }}</span>
                                </div>
                                <div v-else class="child-item-name">
                                    {{ child.name }}
                                </div>
                            </a>
                            <div class="child-item-stats">
                                <DashboardStatsValues
                                    :score="child.score"
                                    :importance="child.importance"
                                    :gap="child.gap"
                                    :respondents="child.respondents"
                                    :scores="child.scores"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Transition>
    </div>
</template>

<script>
import DashboardStatsValues from "@/buying-teams/shared-components/dashboard/DashboardStatsValues";
import { BusinessPageTypes } from "@/store/enums/business/BusinessPageTypes";
import store from "@/store";
import { BankPageTypes } from "@/store/enums/bank/BankPageTypes";
import BankFiltersTypeEnum from "@/store/enums/bank/BankFiltersTypeEnum";

export default {
    name: "DashboardStatsCollapseItem",
    components: {
        DashboardStatsValues
    },
    props: {
        containerItem: Object,
        pageType: String,
        filterData: {
            type: Object,
            required: true
        }
    },
    methods: {
        handleMainItemClick() {
            let filters = {...this.filterData};
            switch (this.pageType) {
                case BusinessPageTypes.BANK:
                    delete filters.bank_ids;
                    store.commit('SAVE_BUSINESS_SINGLE_BANK_DASHBOARD_FILTER', filters);
                    return this.$router.push(`/business/dashboard/banks/${this.containerItem.item.id}`);
                case BusinessPageTypes.COUNTRY:
                    delete filters.countries;
                    store.commit('SAVE_BUSINESS_SINGLE_COUNTRY_DASHBOARD_FILTER', filters);
                    return this.$router.push(`/business/dashboard/countries/${this.containerItem.item.name}`);
                case BusinessPageTypes.PRODUCT_AREA:
                    delete filters.product_areas;
                    store.commit('SAVE_BUSINESS_SINGLE_PRODUCT_AREA_DASHBOARD_FILTER', filters);
                    return this.$router.push(`/business/dashboard/product-areas/${this.containerItem.item.name}`);
                case BankPageTypes.PRODUCT_AREA:
                    store.commit('SET_SAVED_FILTERS_PREV_PAGE', {
                        page: BankFiltersTypeEnum.PRODUCT_AREA_OVERVIEW,
                        filters: { ...this.filterData },
                    });
                    return this.$router.push(`/bank/product-areas/${this.containerItem.item.name}`);
                case BankPageTypes.COUNTRY:
                    store.commit('SET_SAVED_FILTERS_PREV_PAGE', {
                        page: BankFiltersTypeEnum.COUNTRY_OVERVIEW,
                        filters: { ...this.filterData },
                    });
                    return this.$router.push(`/bank/countries/${this.containerItem.item.name}`);

            }
        },
        handleChildItemClick(type, redirectUrl, mainItem) {
            let filters = {...this.filterData};
            switch (type) {
                case BusinessPageTypes.BANK:
                    delete filters.bank_ids;
                    store.commit('SAVE_BUSINESS_SINGLE_BANK_DASHBOARD_FILTER', filters);
                    break;
                case BusinessPageTypes.COUNTRY:
                    delete filters.countries;
                    store.commit('SAVE_BUSINESS_SINGLE_COUNTRY_DASHBOARD_FILTER', filters);
                    break;
                case BusinessPageTypes.PRODUCT_AREA:
                    delete filters.product_areas;
                    store.commit('SAVE_BUSINESS_SINGLE_PRODUCT_AREA_DASHBOARD_FILTER', filters);
                    break;
                case BankPageTypes.PRODUCT_AREA:
                    store.commit('SET_SAVED_FILTERS_PREV_PAGE', {
                        page: BankFiltersTypeEnum.PRODUCT_AREA_OVERVIEW,
                        filters: { ...filters, countries: [mainItem] },
                    });
                    break;
                case BankPageTypes.COUNTRY:
                    store.commit('SET_SAVED_FILTERS_PREV_PAGE', {
                        page: BankFiltersTypeEnum.COUNTRY_OVERVIEW,
                        filters: { ...filters, product_areas: [mainItem] },
                    });
                    break;
            }
            this.$router.push(redirectUrl);
        }
    },
    data() {
        return {
            isCollapsed: false,
            BusinessPageTypes
        };
    }
};
</script>

<style lang="scss">
.stats-collapse {
    &__item {
        &--header {
            background: #FAFAFA;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-radius: 12px;
            padding: 14px;
            padding-left: 6px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: relative;

            .stats-header-left {
                display: flex;
                align-items: stretch;

                &__button {
                    width: 38px;
                    height: 38px;
                    margin-right: 6px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    transition: .3s ease-in-out;
                    cursor: pointer;

                    svg {
                        transition: .3s ease-in-out;
                        transform: rotate(180deg);
                    }

                    &:hover {
                        background: #F6F9FF;

                        svg path {
                            fill: #435BF4;
                        }
                    }
                }

                &__name {
                    display: flex;
                    align-items: center;
                    padding-left: 20px;
                    border-left: 2px solid rgba(0, 0, 0, 0.1);

                    img {
                        width: 30px;
                        height: 30px;
                        border: 1px solid rgba(0, 0, 0, 0.1);
                        border-radius: 50%;
                        object-fit: cover;
                        margin-right: 12px;
                    }

                    span {
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 26px;
                        color: #515151;
                    }
                }
            }
        }

        &--content {
            background: #FFFFFF;
            border: 1px solid rgba(0, 0, 0, 0.1);
            border-top-color: transparent;
            border-radius: 0 0 12px 12px;
            padding: 0 16px 0 20px;
            overflow: hidden;

            .content-item {
                .el-divider {
                    border-color: rgba(0, 0, 0, 0.05);

                    .el-divider__text.is-left {
                        left: 0;
                        padding-left: 0;
                        padding-right: 8px;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 15px;
                        color: rgba(0, 0, 0, 0.5);
                    }
                }

                &__child {
                    &--item {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding: 12px 0;
                        position: relative;

                        &:before {
                            content: "";
                            position: absolute;
                            top: 0;
                            left: -20px;
                            bottom: 0;
                            right: -23px;
                            background: transparent;
                            z-index: 0;
                            transition: .2s ease-in-out;
                        }

                        > * {
                            position: relative;
                            z-index: 1;
                        }

                        &:not(:last-child) {
                            border-bottom: 2px solid rgba(0, 0, 0, 0.1);
                        }

                        &:hover:before {
                            background: #F6F9FF;
                        }

                        .child-item-name {
                            font-weight: 500;
                            font-size: 16px;
                            line-height: 19px;
                            color: #515151;
                        }

                        .child-item-name-image {
                            display: flex;
                            align-items: center;

                            img {
                                width: 20px;
                                height: 20px;
                                border-radius: 50%;
                                object-fit: cover;
                                border: 1px solid rgba(0, 0, 0, 0.1);
                                margin-right: 10px;
                            }

                            span {
                                font-weight: 500;
                                font-size: 16px;
                                line-height: 15px;
                                color: #000000;
                            }
                        }
                    }
                }
            }
        }

        &.is-collapsed {
            .stats-collapse__item--header {
                border-radius: 12px 12px 0 0;
            }

            .stats-collapse__item--header .stats-header-left__button svg {
                transform: rotate(0);
            }
        }
    }

    @media (max-width: 1200px) {
        &__item {
            .stats-values {
                min-width: 360px;
            }

            &--header {
                .stats-header-left {
                    &__button {
                        width: 30px;
                        height: 30px;
                        margin-right: 4px;
                    }

                    &__name {
                        padding-left: 10px;

                        img {
                            width: 24px;
                            height: 24px;
                            margin-right: 8px;
                        }

                        span {
                            font-size: 14px;
                        }
                    }
                }
            }
        }
    }
    @media (max-width: 768px) {
        &__item {
            .stats-values {
                min-width: 300px;
            }

            &--header {
                flex-direction: column;
                align-items: flex-start;
                gap: 10px;

                .stats-header-right {
                    padding-right: 0;
                    margin-left: 12px;
                }
            }

            &--content .content-item__child--item {
                flex-direction: column;
                align-items: flex-start;
                gap: 10px;

                .child-item-name {
                    font-size: 14px;
                    line-height: 120%;
                }
            }
        }
    }
}

.fade-enter-active {
    transition: all 0.3s ease-out;
}

.fade-leave-active {
    transition: all 0.3s ease-in;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
